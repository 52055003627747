import React from 'react';
import ButtonAndTitle from './buttonAndTitle';

const QuestionsTout = () => {
  return (
    <ButtonAndTitle
      title="Are you ready to talk to your doctor about UTUC and your treatment
      options?"
      link="/treatment-options#talk_with_your_doctor"
      linkText="See a list of questions to ask"
      buttonStyle="lowgrade__button"
    />
  );
};

export default QuestionsTout;
