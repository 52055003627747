import './talkWithDoc.scss';

import React from 'react';
import Definition from '../definition/definition';
import tw from 'twin.macro';

const TreatmentTalkWithDoc = ({ onLoadinComplete }) => {
  return (
    <section
      className="subsection treatment__subsection"
      data-id="studies"
      aria-label="clinical studies"
    >
      <h3 className="subsection__title">
        Ask questions and get as much information as possible about your UTUC so
        that you and your urologist can choose the best treatment as a team
      </h3>
      <div tw="flex items-start flex-col-reverse md:(gap-12 flex-row)">
        <div tw="flex flex-col">
          <p tw="font-semibold mt-12">
            Things to keep in mind as you get ready to talk to your doctor:
          </p>
          <div>
            <h4 tw="font-bold text-base pb-2 pt-6">Cancer grade</h4>
            <p>
              When choosing a treatment for UTUC, the most important thing to
              think about is <Definition term="grade" id="grade" />:<br /> Is
              your cancer <Definition term="low grade" id="low_grade" /> or{' '}
              <Definition term="high grade" id="high_grade" />?
            </p>
          </div>
          <div>
            <h4 tw="font-bold text-base pb-2 pt-6">Your treatment options</h4>
            <p>
              UTUC is a serious disease, but it can be treated. Use the tabs
              above to read about which treatment might be right for you.
            </p>
          </div>
          <div>
            <h4 tw="font-bold text-base pb-2 pt-6">Creating a plan</h4>
            <p>
              It is important for you and your doctor to come up with a
              treatment plan. Be sure to talk to your{' '}
              <Definition term="urologist" id="urologist" /> about all of the
              steps you will take to treat your UTUC—not just the next step.
            </p>
          </div>
          <div>
            <h4 tw="font-bold text-base pb-2 pt-6">
              Important questions to ask
            </h4>
            <p tw="pb-4">Is my cancer low grade or high grade?</p>
            <p tw="pb-4">
              What <Definition term="stage" id="stage" /> is my cancer?
            </p>
            <p tw="pb-4">
              Where is my <Definition term="tumor" id="tumor" />
              /where are my tumors located?
            </p>
            <p tw="pb-4">What are my options for treatment?</p>
            <p tw="pb-4">
              What do the treatments mean for me outside of treating my cancer?
            </p>
            <p tw="pb-4">Is there a long recovery?</p>
            <p tw="pb-4">
              Will my treatments have an impact on my life or general health?
            </p>
            <p tw="pb-4">
              What are the chances that my cancer could come back?
            </p>
            <p tw="pb-4">
              How many times per year do you do this treatment/procedure?
            </p>
            <p tw="pb-8">
              If this treatment doesn't work or my cancer comes back, what will
              we do next?
            </p>
            <p tw="pb-2">
              You can also ask your urologist for a copy of your pathology
              reports, CT scans, and operative reports.
            </p>
            <p tw="pb-8">
              These are important documents to have for your records.
            </p>
          </div>
          <div>
            <p>
              Don't be afraid to ask more questions or get a second opinion.
              Choosing a cancer treatment is a very important decision.
            </p>
          </div>
        </div>
        <div tw="background[#E9F0F3] py-6 px-4 rounded-2xl max-w-xs text-brand-blue-darker">
          <h4>Getting ready for your next appointment</h4>
          <p tw="mt-2 mb-6">
            To make sure you’re ready to talk to your doctor, print out this
            helpful guide and bring it to your next appointment.
          </p>
          {/* TODO: Add download link */}
          <a
            download
            target="_blank"
            rel="noopener noreferrer"
            href="/pdf/discussion-guide.pdf"
            tw="text-brand-red"
          >
            Download PDF
          </a>
        </div>
      </div>
    </section>
  );
};

export default TreatmentTalkWithDoc;
