import './lowgrade.scss';

import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';

import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import CalloutEffects from '../calloutEffects';
import QuestionsTout from '../questionsTout';
import Definition from '../definition/definition';

import iconSurgical from '../../images/icon-surgical.svg';
import iconNonSurgical from '../../images/icon-nonsurgical.svg';
import iconMinorSurgical from '../../images/icon-scope.svg';
import iconEye from '../../images/icon-eye.svg';
import { getParam } from '../utils';
import 'twin.macro';

const TreatmentLowGrade = ({ onLoadinComplete, location, activeSection }) => {
  const knownTabs = [
    'wait-and-watch',
    'nonsurgical',
    'minor-surgery',
    'major-surgery',
  ];
  const [activeTab, setActiveTab] = useState(knownTabs[0]);
  const tabParam = 'treatments'; //the ?[tabParam='paramName'] part of the url

  useEffect(() => {
    if (location.hash) {
      const tab = getParam(location.hash, knownTabs);
      if (tab && activeTab !== tab) {
        setActiveTab(tab);
      }
    }
  }, [location]);

  return (
    <section
      className="subsection treatment__subsection"
      data-id="lowgrade"
      aria-label="low grade utuc"
    >
      <h3 className="subsection__title">Treatments for low-grade UTUC</h3>
      <div className="subsection__content_section">
        <p>
          Low-grade UTUC is usually not very aggressive and is slow to spread.
          This typically means it's not as dangerous, so there are a few
          different treatment options that you and your doctor can choose from.
          Be sure to ask your doctor which treatment option would be best for
          treating your cancer.
        </p>
      </div>
      <div
        className="nav-and-tabs full--mobile"
        style={{ marginTop: '30px' }}
        data-id="lowgrade"
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === knownTabs[0] })}
              onClick={() => {
                navigate(`${activeSection}?${tabParam}=${knownTabs[0]}`);
              }}
              tw="border-top-left-radius[.5rem]! border-top-right-radius[.5rem]!"
            >
              <div className="nav-and-tabs__link_content">
                <div className="nav-icon-container">
                  <img
                    className="nav-and-tabs__nav_icon"
                    data-id="wait"
                    src={iconEye}
                    alt="Icon of an eye"
                  />
                </div>
                Wait and watch
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === knownTabs[1] })}
              onClick={() => {
                navigate(`${activeSection}?${tabParam}=${knownTabs[1]}`);
              }}
              tw="border-top-left-radius[.5rem]! border-top-right-radius[.5rem]!"
            >
              <div className="nav-and-tabs__link_content">
                <div className="nav-icon-container">
                  <img
                    className="nav-and-tabs__nav_icon"
                    data-id="nonsurgical"
                    src={iconNonSurgical}
                    alt="Icon of a scalper with a slashed circle over it"
                  />
                </div>
                Nonsurgical treatments
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === knownTabs[2] })}
              onClick={() => {
                navigate(`${activeSection}?${tabParam}=${knownTabs[2]}`);
              }}
              tw="border-top-left-radius[.5rem]! border-top-right-radius[.5rem]!"
            >
              <div className="nav-and-tabs__link_content">
                <div className="nav-icon-container">
                  <img
                    className="nav-and-tabs__nav_icon"
                    data-id="minorsurgery"
                    src={iconMinorSurgical}
                    alt="Icon of a scalpel"
                  />
                </div>
                Minor surgery
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === knownTabs[3] })}
              onClick={() => {
                navigate(`${activeSection}?${tabParam}=${knownTabs[3]}`);
              }}
              tw="border-top-left-radius[.5rem]! border-top-right-radius[.5rem]!"
            >
              <div className="nav-and-tabs__link_content">
                <div className="nav-icon-container">
                  <img
                    className="nav-and-tabs__nav_icon"
                    data-id="majorsurgery"
                    src={iconSurgical}
                    alt="Icon of a heart with a pulse rate over it"
                  />
                </div>
                Major surgery
              </div>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={knownTabs[0]}>
            <div
              className="tab-pane__content tab-pane__content--low-grade"
              data-id={knownTabs[0]}
            >
              <h4 className="title--body-semibold">What is it?</h4>
              <p>
                If your urologist was able to remove all of your tumor during
                your biopsy or a minor surgery, they might want to wait and
                watch—to see if the tumor comes back. You’ll probably be asked
                to come back for more appointments over the next few weeks or
                months so your doctor can run more tests and keep an eye on it.
              </p>
              <h4 className="title--body-semibold">
                Why it might be right for you
              </h4>
              <p>
                If your doctor wants to wait and watch, it means they’re pretty
                sure your whole tumor was removed. That’s great news! You might
                not need any more treatment right now. That said, your doctor
                will probably keep running tests to make sure the tumor hasn't
                come back.
              </p>
              <h4 className="title--body-semibold">
                Why it might NOT be right for you
              </h4>
              <p>
                If you still have a tumor or part of a tumor, it’s important to
                get treated to try to prevent further serious problems. Even if
                your urologist already tried to remove your tumor, if they
                weren’t able to remove the whole thing, you might need to get
                more treatment.
              </p>
            </div>
            <div className="divider"></div>
            <QuestionsTout />
          </TabPane>
          <TabPane tabId={knownTabs[1]}>
            <div className="tab-pane__content" data-id={knownTabs[1]}>
              <h4 className="title--body-semibold">What is it?</h4>
              <p>
                There’s a nonsurgical treatment option available that your
                urologist might administer, directly at the location of your
                tumor, through a <Definition term="catheter" id="catheter" /> or{' '}
                <span tw="whitespace-nowrap">
                  <Definition term="nephrostomy tube" id="nephrostomy_tube" />.
                </span>{' '}
                Be sure to talk to your urologist before choosing a
                treatment—they can discuss more details with you.
              </p>
              <h4 className="title--body-semibold">
                Why it might be right for you
              </h4>
              <p>
                Because this is typically an outpatient procedure, it doesn’t
                require a long recovery. You’ll usually be sent home the same
                day after each treatment.
              </p>
              <p>
                This treatment can be used in people who have recently been
                diagnosed with low-grade UTUC as well as people whose low-grade
                UTUC has come back.
              </p>
              <h4 className="title--body-semibold">
                Why it might NOT be right for you
              </h4>
              <p>
                If you have a hole or tear (also known as a perforation) of your
                bladder or upper urinary tract, this procedure wouldn’t be right
                for you.
              </p>
            </div>
            <div className="divider"></div>
            <QuestionsTout />
          </TabPane>
          <TabPane tabId={knownTabs[2]}>
            <div className="tab-pane__content" data-id={knownTabs[2]}>
              <h4 className="title--body-semibold">What is it?</h4>
              <p>
                There are a few minor operations that you can get to remove
                low-grade UTUC tumors. Your urologist might use a laser,&nbsp;
                <Definition term="forceps" id="forceps" />, or another surgical
                tool to remove your tumor. No matter what tool your urologist
                uses, you’ll usually be put to sleep during the operation.
              </p>
              <p>
                Your urologist might call these operations{' '}
                <Definition
                  displayAs="endoscopic resection"
                  term="endoscopic management"
                  id="endoscopic_management"
                />{' '}
                or{' '}
                <Definition
                  term="endoscopic management"
                  id="endoscopic_management"
                />{' '}
                because they’re done using a piece of equipment called an{' '}
                <Definition term="endoscope" id="endoscope" />. An endoscope is
                a camera that lets a doctor see the inside of your body while
                they do an operation. The type of endoscope that urologists use
                is called a <Definition term="ureteroscope" id="ureteroscope" />{' '}
                (ur-EE-tero-scope).
              </p>
              <h4 className="title--body-semibold">
                Why it might be right for you
              </h4>
              <ul className="bullet-list bullet-list--no-space">
                <li className="bullet-list__item">
                  <span>
                    With these operations, you’ll usually be able to go home the
                    same day and won’t have to spend the night in the hospital
                  </span>
                </li>
                <li className="bullet-list__item">
                  <span>
                    These operations are relatively safe and don’t need a long
                    recovery. Also, they usually don’t cause any long-term
                    damage to your kidneys or ureters
                  </span>
                </li>
                <li className="bullet-list__item">
                  <span>
                    Since you’ll be put to sleep or take medicine to numb the
                    pain, these operations usually aren’t too painful
                  </span>
                </li>
              </ul>
              <h4 className="title--body-semibold">
                Why it might NOT be right for you
              </h4>
              <ul className="bullet-list bullet-list--no-space">
                <li className="bullet-list__item">
                  <span>
                    These operations don’t work all the time. You might have to
                    go back for more than one operation if your whole tumor
                    can’t be removed the first time
                  </span>
                </li>
                <li className="bullet-list__item">
                  <span>
                    Even if your operation works the first time, your cancer
                    could come back. In fact, studies have shown that cancer
                    comes back in about 65% of patients who are treated with{' '}
                    <Definition
                      displayAs="endoscopic surgery"
                      id="endoscopic_management"
                    />
                    . Then you would have to decide if you want another
                    operation or if you want to try a different treatment
                  </span>
                </li>
                <li className="bullet-list__item">
                  <span>
                    The exact location of your tumor could make these operations
                    more difficult. If the tumor is in the small parts of your
                    kidney, it might be hard to reach. And if it’s in a really
                    tough spot, your urologist might not be able to reach it at
                    all
                  </span>
                </li>
              </ul>
            </div>
            <div className="divider"></div>
            <QuestionsTout />
          </TabPane>
          <TabPane tabId={knownTabs[3]}>
            <div className="tab-pane__content" data-id={knownTabs[3]}>
              <h4 className="title--body-semibold">What is it?</h4>
              <p>
                If your doctor thinks your low-grade UTUC is serious enough,
                they might recommend an operation to remove your kidney. This
                could mean removing your whole kidney, your ureter, and possibly
                a small part of your bladder. It could also mean removing just a
                part of your kidney or ureter. It might seem like a scary
                option, but plenty of people live full and healthy lives with
                only one kidney.
              </p>
              <p>
                Your doctor might call this operation an{' '}
                <Definition term="NU" id="nephroureterectomy" /> or an&nbsp;
                <Definition term="RNU" id="radical_nephroureterectomy" />, which
                stands for{' '}
                <Definition term="nephroureterectomy" id="nephroureterectomy" />{' '}
                (neff-roh-ureter-EC-toh-mee) or{' '}
                <Definition
                  term="radical nephroureterectomy"
                  id="radical_nephroureterectomy"
                />
                .
              </p>

              <h4 className="title--body-semibold">
                Why it might be right for you
              </h4>
              <p>
                This option is the only way to guarantee that your cancer is
                completely removed. If your cancer is serious enough, this might
                be the best option to help you move on with your life
                cancer-free.
              </p>
              <p>
                There are several things that might make your case serious
                enough for this surgery to be right for you:
              </p>
              <ul className="bullet-list bullet-list--no-space">
                <li className="bullet-list__item">
                  <span>
                    You have a large tumor that can’t be fully removed with
                    minor surgery
                  </span>
                </li>
                <li className="bullet-list__item">
                  <span>
                    Your tumor is located in the small parts of your kidney and
                    can’t be reached with minor surgery
                  </span>
                </li>
                <li className="bullet-list__item">
                  <span>
                    You have more than one tumor and they can’t all be removed
                    with minor surgery
                  </span>
                </li>
              </ul>
              <h4 className="title--body-semibold">
                Why it might NOT be right for you
              </h4>
              <p>
                If you have certain health conditions, this operation might not
                be right for you. You and your doctor should think very hard
                about this operation if
              </p>
              <ul className="bullet-list bullet-list--no-space">
                <li className="bullet-list__item">
                  <span>You have only one kidney</span>
                </li>
                <li className="bullet-list__item">
                  <span>
                    You have{' '}
                    <Definition
                      term="chronic kidney disease"
                      id="chronic_kidney_disease"
                    />{' '}
                    or another disease that affects your kidneys
                  </span>
                </li>
                <li className="bullet-list__item">
                  <span>You’re generally in poor health</span>
                </li>
              </ul>
            </div>
            <div className="divider"></div>
            <CalloutEffects />
            <QuestionsTout />
          </TabPane>
        </TabContent>
      </div>
    </section>
  );
};

export default TreatmentLowGrade;
