import React from 'react';
import Definition from './definition/definition';

const CalloutEffects = () => {
  return (
    <div className="callout full">
      <h3 className="callout__title">Short-term and long-term effects</h3>
      <h4 className="title--body-semibold" style={{ marginTop: '0px' }}>
        Short term
      </h4>
      <ul className="bullet-list bullet-list--no-space">
        <li className="bullet-list__item">
          <span>You could spend several days in the hospital</span>
        </li>
        <li className="bullet-list__item">
          <span>
            You might have to have a{' '}
            <Definition term="catheter" id="catheter" /> for a few
            hours&mdash;up to a day&mdash;after your surgery to help you urinate
          </span>
        </li>
        <li className="bullet-list__item">
          <span>
            You’ll have to miss time from work—sometimes 30 days or more
          </span>
        </li>
        <li className="bullet-list__item">
          <span>
            A family member or caregiver may need to help you recover—and that
            could mean missing time from work
          </span>
        </li>
        <li className="bullet-list__item">
          <span>You won’t be able to drive for at least 1 to 2 weeks</span>
        </li>
        <li className="bullet-list__item">
          <span>
            You won’t be able to exercise or do any major activities for up to 6
            weeks
          </span>
        </li>
      </ul>
      <h4 className="title--body-semibold">Long term</h4>
      <p>
        Having only one kidney could have a serious impact on your life and
        overall health.
      </p>
      <ul className="bullet-list bullet-list--no-space">
        <li className="bullet-list__item">
          <span>
            With only one kidney, you have a higher chance of getting{' '}
            <Definition
              term="chronic kidney disease"
              id="chronic_kidney_disease"
            />
            , which is a very serious disease
          </span>
        </li>
        <li className="bullet-list__item">
          <span>
            You may not be able to take certain medicines or get other
            treatments in the future
          </span>
        </li>
        <li className="bullet-list__item">
          <span>
            Major surgery is not a guaranteed cure—your cancer could still come
            back in your bladder or your remaining kidney
          </span>
        </li>
      </ul>
    </div>
  );
};

export default CalloutEffects;
