import './highgrade.scss';

import React from 'react';

import iconSurgical from '../../images/icon-surgical.svg';
import CalloutEffects from '../calloutEffects';
import QuestionsTout from '../questionsTout';
import Definition from '../definition/definition';
import 'twin.macro';

const TreatmentHighGrade = ({ onLoadinComplete }) => {
  return (
    <section
      className="subsection treatment__subsection"
      data-id="highgrade"
      aria-label="high grade utuc"
    >
      <h3 className="subsection__title">Treatments for high-grade UTUC</h3>
      <div className="highrade__icon_header icon__header">
        <img
          src={iconSurgical}
          alt="Icon of a Scalpel"
          className="highrade__icon_image icon__image"
        />
        <h4 className="highrade__icon_title icon__title">Major surgery</h4>
      </div>
      <div tw="">
        <div
          // className="flexgroup--desktop reverse highgrade-content"
          tw="grid grid-template-columns[1fr] gap-6 xl:(grid-template-columns[auto minmax(auto, 65ch)] gap-6)"
        >
          <div
            className="tab-pane__content"
            style={{ marginTop: '24px' }}
            tw="grid-row[1] grid-column[1/3] flex flex-col xl:(grid-column[1])"
          >
            <h5 className="title--body-semibold">What is it?</h5>
            <p>
              High-grade UTUC is very serious. To make sure your whole tumor is
              removed, major surgery is the only option. Usually, this means
              removing your whole kidney, your ureter, and a small part of your
              bladder. It might seem like a scary option, but plenty of people
              live full and healthy lives with only one kidney.
            </p>
            <p>
              Your doctor might call this operation an{' '}
              <Definition term="NU" id="nephroureterectomy" /> or an{' '}
              <Definition term="RNU" id="radical_nephroureterectomy" />, which
              stands for{' '}
              <Definition term="nephroureterectomy" id="nephroureterectomy" />{' '}
              (neff-roh-ureter-EC-toh-mee) or{' '}
              <Definition
                term="radical nephroureterectomy"
                id="radical_nephroureterectomy"
              />
              .
            </p>
            <p>
              Make sure to think about exactly what this operation would mean
              for you before you get it. And talk with your doctor about other
              things you can do to fight your cancer. There might be additional
              treatments that can help keep your cancer from spreading or
              getting worse.
            </p>
          </div>
          <div tw="grid-row[3] grid-column[1/3] xl:(grid-column[1] grid-row[2])">
            <h4 className="title--body-semibold">
              Why it might be right for you
            </h4>
            <p>
              This option is the only way to guarantee that your cancer is
              completely removed. If your cancer is serious enough, this might
              be the best option to help you move on with your life cancer-free.
              There are several things that might make your case serious enough
              for this surgery to be right for you:
            </p>
            <ul className="bullet-list bullet-list--no-space">
              <li className="bullet-list__item">
                <span>
                  You have a large tumor that can’t be fully removed with minor
                  surgery
                </span>
              </li>
              <li className="bullet-list__item">
                <span>
                  Your tumor is located in the small parts of your kidney and
                  can’t be reached with minor surgery
                </span>
              </li>
              <li className="bullet-list__item">
                <span>
                  You have more than one tumor and they can’t all be removed
                  with minor surgery
                </span>
              </li>
            </ul>
          </div>
          <div tw="grid-row[4] grid-column[1/3] xl:(grid-column[1] grid-row[3])">
            <h4 className="title--body-semibold">
              Why it might NOT be right for you
            </h4>
            <p>
              If you have certain health conditions, this operation might not be
              right for you. You and your doctor should think very hard about
              this operation if
            </p>
            <ul className="bullet-list bullet-list--no-space">
              <li className="bullet-list__item">
                <span>You only have one kidney</span>
              </li>
              <li className="bullet-list__item">
                <span>
                  You have{' '}
                  <Definition
                    term="chronic kidney disease"
                    id="chronic_kidney_disease"
                  />{' '}
                  or another disease that affects your kidneys
                </span>
              </li>
              <li className="bullet-list__item">
                <span>You're generally in poor health</span>
              </li>
            </ul>
          </div>
          <div
            className="highrade__tout"
            tw="align-self[start] grid-row[2] grid-column[1] my-4! max-w-full! mx-0! xl:(grid-row[1] grid-column[2] ml-12! mb-[-4rem]!)"
          >
            <h3 className="subsection__title blue">
              Chemotherapy and major surgery
            </h3>
            <p className="color--blue-dark">
              Your urologist might also recommend a round of chemotherapy
              treatment. This is something that is usually only done for
              high-grade UTUC, and it’s an extra precaution to help make sure
              the cancer doesn't spread to other parts of your urinary tract
            </p>
          </div>
        </div>
      </div>
      <CalloutEffects />
      <QuestionsTout />
    </section>
  );
};

export default TreatmentHighGrade;
