import './choosing.scss';

import React from 'react';
import { Link } from 'gatsby';
import iconConversation from '../../images/icon-conversation.svg';
import Definition from '../definition/definition';

const TreatmentChoosing = ({ onLoadinComplete }) => {
  return (
    <section
      className="subsection treatment__subsection"
      data-id="choosing"
      aria-label="choosing a treatment"
    >
      <div className="treatment__choosing_content_container">
        <div className="treatment__choosing_image_container">
          <img
            src={iconConversation}
            alt="icon of a patient and doctor with speech bubbles"
            className="treatment__choosing_image"
          />
        </div>
        <div className="treatment__choosing__content subsection__content_section">
          <h3 className="subsection__title treatment__choosing_title">
            How to choose a treatment with your doctor
          </h3>
          <p>
            When you were diagnosed, your{' '}
            <Definition term="urologist" id="urologist" /> probably did a{' '}
            <Definition term="biopsy" id="biopsy" />. There’s a chance your
            urologist was able to remove the entire tumor during that biopsy.
            Even so, treatment following the removal of the tumor can help
            ensure any lingering cancer is taken care of.
          </p>
          <p>
            When it comes to choosing a treatment, the most important thing to
            keep in mind is the <Definition term="grade" id="grade" /> of your
            cancer. Click <strong className="bold">Low grade</strong> or{' '}
            <strong className="bold">High grade</strong> above to see the
            different treatment options for each grade of UTUC.
          </p>
          <p>
            Your other medical conditions, including whether you've had kidney
            problems in the past, can also factor into your treatment plan.
          </p>
          <p className="subsection__title blue">
            If you haven’t talked to your urologist about whether your cancer is
            low or high grade, be sure to ask. For help with how to talk to your
            doctor and the right questions to ask, be sure to visit the{' '}
            <strong>Talk with your doctor</strong> tab above.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TreatmentChoosing;
