import './treatment-options.scss';
import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { navigate } from '@reach/router';
import Layout from '../components/layout';
import SEO from '../components/seo';

import cornerImage from '../images/page-header-corner-only.png';

import { snakeCase, gotoHash } from '../components/utils';
import TreatmentChoosing from '../components/treatment/choosing';
import TreatmentLowGrade from '../components/treatment/lowgrade';
import TreatmentHighGrade from '../components/treatment/highgrade';
import TreatmentTalkWithDoc from '../components/treatment/talkWithDoc';

const Treatment = ({ location }) => {
  const { desktop, tablet, mobile } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "people-04-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tablet: file(relativePath: { eq: "people-04-tablet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobile: file(relativePath: { eq: "people-04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  // Art-Direction Array
  const backgroundArtDirectionStack = [
    mobile.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ];

  const navItemData = [
    'Choosing a treatment',
    'Low grade',
    'High grade',
    'Talk with your doctor',
  ];

  const [activeSection, setActiveSection] = useState(
    `#${snakeCase(navItemData[0])}`,
  );

  const switchSection = section => {
    navigate(`#${section}`);
  };

  const ActiveSection = () => {
    let activeComponent;
    switch (activeSection) {
      case `#${snakeCase(navItemData[0])}`:
        activeComponent = <TreatmentChoosing />;
        break;
      case `#${snakeCase(navItemData[1])}`:
        activeComponent = (
          <TreatmentLowGrade
            location={location}
            activeSection={activeSection}
          />
        );
        break;
      case `#${snakeCase(navItemData[2])}`:
        activeComponent = <TreatmentHighGrade />;
        break;
      case `#${snakeCase(navItemData[3])}`:
        activeComponent = <TreatmentTalkWithDoc />;
        break;
      default:
        activeComponent = <TreatmentChoosing />;
        break;
    }
    return activeComponent;
  };

  useEffect(() => {
    if (location.hash) {
      const hash = gotoHash(
        location.hash,
        navItemData,
        '.page-header__nav_list',
      ); //hash, known hashes, selector, smooth(optional)
      if (hash && activeSection !== hash) {
        setActiveSection(hash);
      }
    }
  }, [location.hash]);

  return (
    <Layout>
      <SEO
        title="UTUC | Getting Treated for UTUC: Your Options"
        keywords={[
          'UTUC',
          'upper tract urothelial cancer',
          'upper tract urothelial carcinoma',
          'UTUC treatment',
          'how to treat UTUC',
          'treatment for UTUC',
          'upper tract urothelial cancer treatment',
          'radical nephroureterectomy',
          'nephroureterectomy',
          'endoscopic resection',
        ]}
        description="Finding out that you have any kind of cancer is scary, but UTUC can be treated. Learn about the different UTUC treatment options based on the grade of your cancer"
        page="treatment"
      />

      <div className="treatment__container">
        <BackgroundImage
          Tag="section"
          fluid={backgroundArtDirectionStack}
          className="treatment__header page-header"
          aria-label="Treatment header"
          critical={true}
          fadeIn={false}
          durationFadeIn={0}
        >
          <div className="treatment__header_content">
            <div>
              <h2 className="treatment__header_title page-header__title">
                Getting treated for UTUC
              </h2>
              <div className="page-header__content">
                <p>
                  Finding out that you have any type of cancer is scary. But
                  UTUC can be treated, and the info here can help you get ready
                  to talk to your doctor about your options.
                </p>
              </div>
            </div>
          </div>
          <div className="page-header__nav_container">
            <img
              className="page-header__nav_corner"
              src={cornerImage}
              alt="presentational corner radius for nav menu"
            />
            <nav
              className="page-header__nav treatment__page_nav"
              aria-label="treatment subpages"
            >
              <ul className="page-header__nav_list">
                {navItemData.map((navItem, index) => {
                  const slug = snakeCase(navItem);
                  return (
                    <li
                      key={`understandingNavItem${index}`}
                      className={`page-header__nav_item ${
                        '#' + slug === activeSection ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-header__nav_link"
                        onClick={e => {
                          e.preventDefault();
                          switchSection(slug);
                        }}
                      >
                        {navItem}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </BackgroundImage>
        <div className="page-content">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeSection}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false);
              }}
              classNames="fade"
            >
              <ActiveSection />
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </Layout>
  );
};

export default Treatment;
