import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

const ButtonRound = ({ link, linkText, styleName, newWindow = false }) => {
  if (link.startsWith('/') === false || link.endsWith('.pdf')) {
    return (
      <a href={link} className={classnames('button--round', styleName)} target={newWindow ? '_blank' : ''} rel={newWindow ? 'noopener noreferrer' : ''} >
        <span
          dangerouslySetInnerHTML={{ __html: linkText }}
          className="balance-text"
        />
      </a>
    );
  }
  return (
    <Link to={link} className={classnames('button--round', styleName)}>
      <span
        dangerouslySetInnerHTML={{ __html: linkText }}
        className="balance-text"
      />
    </Link>
  );
};

export default ButtonRound;
