export const snakeCase = (string: string) => {
  return string
    .toLowerCase()
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .join('_');
};

export const gotoHash = (
  hashParam: string,
  knownHashes: string[],
  selector: string,
  doSmoothScroll = true,
) => {
  const hash = hashParam.split('?')[0];
  let isKnownHash = false;
  knownHashes.forEach(knownHash => {
    const navSlug = `#${snakeCase(knownHash)}`;
    if (navSlug === hash) {
      isKnownHash = true;
    }
  });

  let finalHash: string;

  if (isKnownHash) {
    finalHash = hash;
  } else {
    finalHash = `#${snakeCase(knownHashes[0])}`;
  }

  if (selector) {
    const $el = document.querySelector(selector);
    const params = hashParam.split('?')[1];
    if ($el && !params) {
      //if we're clicking on a 2nd tier menu, skip the smooth scroll and keep browser scroll wherever it is
      $el.scrollIntoView({
        behavior: doSmoothScroll ? 'smooth' : 'auto',
      });
    }
  }
  return finalHash;
};

export const getParam = (hash: string, knownTabs: string[]) => {
  let tab: string;
  if (hash) {
    const params = hash.split('?')[1];
    if (params) {
      tab = params.split('=')[1];
      if (tab && knownTabs.includes(tab)) {
        return tab;
      } else {
        return null;
      }
    }
  }
};
