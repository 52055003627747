import React from 'react';
import cx from 'classnames';
import ButtonRound from './buttonRound';

const ButtonAndTitle = ({
  title = 'Title',
  content,
  linkText = 'CTA',
  link = '#',
  buttonStyle,
  newWindow = false,
}) => {
  return (
    <div className="button-and-title__container">
      <div className="button-and-title-content__container">
        <h4 className="button-and-title__title">{title}</h4>
        {content && <div className="button-and-title__content">{content}</div>}
      </div>
      <ButtonRound
        link={link}
        linkText={linkText}
        styleName={cx(buttonStyle, 'button-and-title__button')}
        newWindow={newWindow}
      />
    </div>
  );
};

export default ButtonAndTitle;
